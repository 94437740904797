// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-jsx": () => import("/opt/build/repo/src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-content-page-jsx": () => import("/opt/build/repo/src/templates/content-page.jsx" /* webpackChunkName: "component---src-templates-content-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("/opt/build/repo/src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-app-download-page-jsx": () => import("/opt/build/repo/src/templates/app-download-page.jsx" /* webpackChunkName: "component---src-templates-app-download-page-jsx" */),
  "component---src-templates-about-page-jsx": () => import("/opt/build/repo/src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("/opt/build/repo/src/templates/product-page.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */),
  "component---src-templates-pricing-page-jsx": () => import("/opt/build/repo/src/templates/pricing-page.jsx" /* webpackChunkName: "component---src-templates-pricing-page-jsx" */),
  "component---src-templates-careers-page-jsx": () => import("/opt/build/repo/src/templates/careers-page.jsx" /* webpackChunkName: "component---src-templates-careers-page-jsx" */),
  "component---src-templates-tags-jsx": () => import("/opt/build/repo/src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

